<template>
  <div class="about">
    <div class="card shadow" style="display:inline-block;margin-top:5em;padding:3em;text-align:center;width:70%;border-radius:3em;">
      <img alt="Display Dog Logo" src="@/assets/img/displaydog-logo-500.svg" />
      <h1>404 Page Not Found</h1>
      <hr>
      <h3>Seems that page went on vacation</h3>
    </div>
    <hr style="margin-top:200px;">
    <footer class="footer">
    &copy; Copyright 2019-Present, DisplayDog, all rights reserved <small>(an IOIHAN Company)</small>
    </footer>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: 'about',
  components: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  async mounted() {

  },
  methods: {
    
  }
}
</script>
